export const subStrWithEllipsis = (str, maxLen) => str && str.substr(0, maxLen) + (str.length > maxLen ? "..." : "");
export const toBoolean = value => {
  if (typeof value === 'string') {
    const trimmedStr = value.trim().toLowerCase();
    if (trimmedStr === 'true') {
      return true;
    }
    return false;
  }
  return Boolean(value);
};
export const hasKey = (obj, key) => {
  return obj != null && hasOwnProperty.call(obj, key);
};
export const getTextWidth = (text, font) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = font;
  const width = context.measureText(text).width;
  return Math.ceil(width);
};