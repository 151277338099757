export function getStorageValue(key, defaultValue, prefix) {
  let initial = defaultValue;
  try {
    let value = localStorage.getItem(prefix + key);
    initial = value !== null ? JSON.parse(value) : defaultValue;
  } catch (e) {
    console.warn(e);
  }
  return initial;
}
export function setStorageValue(key, value, prefix) {
  try {
    localStorage.setItem(prefix + key, JSON.stringify(value));
  } catch (e) {
    console.error(e);
  }
}
export function removeStorageValue(key, prefix) {
  try {
    localStorage.removeItem(prefix + key);
  } catch (e) {
    console.error(e);
  }
}
export function clearStorageValues() {
  try {
    localStorage.clear();
  } catch (e) {
    console.error(e);
  }
}