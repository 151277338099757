/* eslint-disable */
export const CACHE_PERIOD = 1800; // 30min
export const CACHE_PERIOD_APP_CONFIG = 3600; // 1hr
export const CACHE_PERIOD_COMMON_CONFIG = 3600; // 1hr

export const SET_CONFIG = "SET_CONFIG";
export const SET_MULTIPLE_CONFIG = "SET_MULTIPLE_CONFIG";
export const CLEAR_CONFIG = "CLEAR_CONFIG";
export const RESET_CONFIG = "RESET_CONFIG";
export const LOAD_APP_CONFIG = "LOAD_APP_CONFIG";
export const LOAD_APP_CONFIG_DONE = "LOAD_APP_CONFIG_DONE";
export const LOAD_APP_CONFIG_ERROR = "LOAD_APP_CONFIG_ERROR";
export const CLEAR_APP_CONFIG = "CLEAR_APP_CONFIG";
export const RESET_APP_CONFIG = "RESET_APP_CONFIG";
export const LOAD_COMMON_CONFIG = "LOAD_COMMON_CONFIG";
export const LOAD_COMMON_CONFIG_DONE = "LOAD_COMMON_CONFIG_DONE";
export const LOAD_COMMON_CONFIG_ERROR = "LOAD_COMMON_CONFIG_ERROR";
export const CLEAR_COMMON_CONFIG = "CLEAR_COMMON_CONFIG";
export const RESET_COMMON_CONFIG = "RESET_COMMON_CONFIG";
export const LOAD_COUNTRY_CONFIG = "LOAD_COUNTRY_CONFIG";
export const LOAD_COUNTRY_CONFIG_DONE = "LOAD_COUNTRY_CONFIG_DONE";
export const LOAD_COUNTRY_CONFIG_ERROR = "LOAD_COUNTRY_CONFIG_ERROR";
export const RESET_COUNTRY_CONFIG = "RESET_COUNTRY_CONFIG";
export const LOAD_COUNTRY_PRODUCTION_ALL = "LOAD_COUNTRY_PRODUCTION_ALL";
export const LOAD_COUNTRY_PRODUCTION_ALL_DONE = "LOAD_COUNTRY_PRODUCTION_ALL_DONE";
export const LOAD_COUNTRY_PRODUCTION_ALL_ERROR = "LOAD_COUNTRY_PRODUCTION_ALL_ERROR";
export const RESET_COUNTRY_PRODUCTION_ALL = "RESET_COUNTRY_PRODUCTION_ALL";
export const LOAD_COUNTRY_BOUNDARY_PRIMARY = "LOAD_COUNTRY_BOUNDARY_PRIMARY";
export const LOAD_COUNTRY_BOUNDARY_PRIMARY_DONE = "LOAD_COUNTRY_BOUNDARY_PRIMARY_DONE";
export const LOAD_COUNTRY_BOUNDARY_PRIMARY_ERROR = "LOAD_COUNTRY_BOUNDARY_PRIMARY_ERROR";
export const RESET_COUNTRY_BOUNDARY_PRIMARY = "RESET_COUNTRY_BOUNDARY_PRIMARY";
export const LOAD_COUNTRY_PRODUCTION_FARM_STATS_CAMPAIGN_FLOW = "LOAD_COUNTRY_PRODUCTION_FARM_STATS_CAMPAIGN_FLOW";
export const LOAD_COUNTRY_PRODUCTION_FARM_STATS_CAMPAIGN_FLOW_DONE = "LOAD_COUNTRY_PRODUCTION_FARM_STATS_CAMPAIGN_FLOW_DONE";
export const LOAD_COUNTRY_PRODUCTION_FARM_STATS_CAMPAIGN_FLOW_ERROR = "LOAD_COUNTRY_PRODUCTION_FARM_STATS_CAMPAIGN_FLOW_ERROR";
export const RESET_COUNTRY_PRODUCTION_FARM_STATS_CAMPAIGN_FLOW = "RESET_COUNTRY_PRODUCTION_FARM_STATS_CAMPAIGN_FLOW";
export const LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_PRICE_VARIATION = "LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_PRICE_VARIATION";
export const LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_PRICE_VARIATION_DONE = "LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_PRICE_VARIATION_DONE";
export const LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_PRICE_VARIATION_ERROR = "LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_PRICE_VARIATION_ERROR";
export const RESET_COUNTRY_PRODUCTION_FARM_STATS_FARM_PRICE_VARIATION = "LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_PRICE_VARIATION";
export const LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_LAND = "LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_LAND";
export const LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_LAND_DONE = "LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_LAND_DONE";
export const LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_LAND_ERROR = "LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_LAND_ERROR";
export const RESET_COUNTRY_PRODUCTION_FARM_STATS_FARM_LAND = "RESET_COUNTRY_PRODUCTION_FARM_STATS_FARM_LAND";
export const LOAD_COUNTRY_PRODUCTION_AVG_YIELD = "LOAD_COUNTRY_PRODUCTION_AVG_YIELD";
export const LOAD_COUNTRY_PRODUCTION_AVG_YIELD_DONE = "LOAD_COUNTRY_PRODUCTION_AVG_YIELD_DONE";
export const LOAD_COUNTRY_PRODUCTION_AVG_YIELD_ERROR = "LOAD_COUNTRY_PRODUCTION_AVG_YIELD_ERROR";
export const RESET_COUNTRY_PRODUCTION_AVG_YIELD = "RESET_COUNTRY_PRODUCTION_AVG_YIELD";
export const LOAD_COUNTRY_PRODUCTION_VOLUME = "LOAD_COUNTRY_PRODUCTION_VOLUME";
export const LOAD_COUNTRY_PRODUCTION_VOLUME_DONE = "LOAD_COUNTRY_PRODUCTION_VOLUME_DONE";
export const LOAD_COUNTRY_PRODUCTION_VOLUME_ERROR = "LOAD_COUNTRY_PRODUCTION_VOLUME_ERROR";
export const RESET_COUNTRY_PRODUCTION_VOLUME = "RESET_COUNTRY_PRODUCTION_VOLUME";
export const LOAD_COUNTRY_PRODUCTION_COSTS = "LOAD_COUNTRY_PRODUCTION_COSTS";
export const LOAD_COUNTRY_PRODUCTION_COSTS_DONE = "LOAD_COUNTRY_PRODUCTION_COSTS_DONE";
export const LOAD_COUNTRY_PRODUCTION_COSTS_ERROR = "LOAD_COUNTRY_PRODUCTION_COSTS_ERROR";
export const RESET_COUNTRY_PRODUCTION_COSTS = "RESET_COUNTRY_PRODUCTION_COSTS";
export const LOAD_RESOURCE_CONFIG = "LOAD_RESOURCE_CONFIG";
export const LOAD_RESOURCE_CONFIG_DONE = "LOAD_RESOURCE_CONFIG_DONE";
export const LOAD_RESOURCE_CONFIG_ERROR = "LOAD_RESOURCE_CONFIG_ERROR";
export const RESET_RESOURCE_CONFIG = "RESET_RESOURCE_CONFIG";
export const LOAD_GLOBAL_PRODUCTION_VOLUME = "LOAD_GLOBAL_PRODUCTION_VOLUME";
export const LOAD_GLOBAL_PRODUCTION_VOLUME_DONE = "LOAD_GLOBAL_PRODUCTION_VOLUME_DONE";
export const LOAD_GLOBAL_PRODUCTION_VOLUME_ERROR = "LOAD_GLOBAL_PRODUCTION_VOLUME_ERROR";
export const RESET_GLOBAL_PRODUCTION_VOLUME = "RESET_GLOBAL_PRODUCTION_VOLUME";
export const USD = 'USD';